<template>
  <div>
    <h2 class="text-center">合同列表</h2>

    <Row class="m-t-10 m-b-5">
      <i-col span="12">
        <span class="title">发布费合计：</span>{{formatMoney(useTotal)}}
      </i-col>
      <i-col span="12">
        <span class="title">服务费合计：</span>{{formatMoney(serviceTotal)}}
      </i-col>
    </Row>

    <Table class="contractTable" size="small" :columns="contractColumn" :data="contractData" :row-class-name="rowClassName"
      @on-row-click="handleClickRow"></Table>
    <div class="paging_style">
        <Page size="small" :total="showArray.length" :page-size="currentPageSize" :current="currentPageNumber" @on-change="handlePageChange"></Page>
    </div>

    <h2 class="text-center m-t-20">售卖动态</h2>
    <Row class="m-t-5">
      <i-col span="24">
        <span class="title m-r-10">日期</span>
        <RadioGroup v-model="chooseDateType" size="small" type="button" button-style="solid">
            <Radio label="1">今日</Radio>
            <Radio label="2">昨日</Radio>
            <Radio label="3">7日内</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <Row class="m-t-5 m-b-10">
      <i-col span="24">
        <span class="title m-r-10">类型</span>
        <RadioGroup v-model="chooseListType" size="small" type="button" button-style="solid">
            <Radio label="1">订单</Radio>
            <Radio label="2">合同</Radio>
        </RadioGroup>
      </i-col>
    </Row>

    <template v-if="chooseListType==='1'">
      <Row  class="table-title" :gutter="8">
        <i-col span="8">客户名称</i-col>
        <i-col span="7">销售</i-col>
        <i-col span="9">提交时间</i-col>
      </Row>
      <Row v-for="(item,index) in dynamicOrderData" :key="index" :gutter="8" class="p-t-10">
        <i-col span="8">
          <Tooltip v-if="item.advertiserName.length>6" placement="right-start" :content="item.advertiserName">
            {{item.advertiserName.substr(0,6)}}...
          </Tooltip>
          <span v-else>{{item.advertiserName}}</span>
        </i-col>
        <i-col span="7">{{item.userName}}</i-col>
        <i-col span="9">{{item.submitTime}}</i-col>
      </Row>
      <p v-if="dynamicOrderData.length===0" class="remark p-t-20 text-center">暂无数据</p>
    </template>

    <template v-else>
      <Row  class="table-title" :gutter="8">
        <i-col span="7">客户名称</i-col>
        <i-col span="4">销售</i-col>
        <i-col span="4">金额</i-col>
        <i-col span="9">提交时间</i-col>
      </Row>
      <Row v-for="(item,index) in dynamicContractData" :key="index" :gutter="8" class="p-t-10">
        <i-col span="7">
          <Tooltip v-if="item.advertiserName.length>6" placement="right-start" :content="item.advertiserName">
            {{item.advertiserName.substr(0,6)}}...
          </Tooltip>
          <span v-else>{{item.advertiserName}}</span>
        </i-col>
        <i-col span="4">
          <Tooltip v-if="item.userName.length>3" placement="right-start" :content="item.userName">
            {{item.userName.substr(0,3)}}...
          </Tooltip>
          <span v-else>{{item.userName}}</span>
        </i-col>
        <i-col span="4">{{item.amount}}</i-col>
        <i-col span="9">{{item.firstApprovedTime}}</i-col>
      </Row>
      <p v-if="dynamicContractData.length===0" class="remark p-t-20 text-center">暂无数据</p>
    </template>
  </div>
</template>

<script>
import { toNumber, toMoney } from '@/utils/wnumb_own'
import { ParseDate, GetDateStr } from '@/utils/dateFormat'
import { listPublisherContractsByOrderType, listContracts } from '@/api/dw/datav'
import { listOrders } from '@/api/dw/order'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      contractColumn: [
        { title: '客户名称', key: 'advertiserName', tooltip: true },
        { title: '发布品牌', key: 'brandName', tooltip: true },
        {
          title: '服务费',
          align: 'right',
          width: 50,
          render: (h, params) => {
            return h('span', toNumber(params.row.signServicePrice))
          }
        },
        {
          title: '发布费',
          align: 'right',
          width: 60,
          render: (h, params) => {
            return h('span', toNumber(params.row.signUsePrice))
          }
        },
        {
          title: '折扣率',
          align: 'right',
          width: 60,
          render: (h, params) => {
            return h('span', params.row.discountRate + '%')
          }
        }
      ],
      contractData: [],
      showArray: [],
      currentPageSize: 15,
      currentPageNumber: 1,
      chooseRightTableId: null,

      chooseDateType: '1',
      chooseListType: '1',

      dynamicOrderData: [],
      dynamicContractData: [],

      serviceTotal: 0,
      useTotal: 0
    }
  },
  mounted () {
    this.initDataColumn()
    this.loadContractListData()
    this.loadContractDynamic()
  },
  methods: {
    initDataColumn () {
      if (this.publisherId === 21) { // 北京轨交需要隐藏附件列
        this.contractColumn.splice(1, 5)
        this.contractColumn.push(
          {
            title: '合同总额',
            align: 'right',
            width: 100,
            render: (h, params) => {
              return h('span', toMoney(params.row.signServicePrice + params.row.signUsePrice))
            }
          }
        )
      }
    },
    loadContractListData () {
      this.chooseRightTableId = null
      this.$store.commit('set_situation_rightTableId', null)
      this.$store.commit('set_situation_rightTableRowBean', null)

      const postData = {
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        orderType: this.orderType,
        companyId: this.companyId,
        industryId: this.gbType === 'industry' ? this.gbId : null,
        platformIndustryId: this.gbType === 'inter_industry' ? this.gbId : null
      }

      listPublisherContractsByOrderType(postData).then(res => {
        this.showArray = res
        this.handlePageChange(1)

        this.serviceTotal = 0
        this.useTotal = 0
        res.forEach(element => {
          this.serviceTotal += element.signServicePrice
          this.useTotal += element.signUsePrice
        })
      })
    },
    handlePageChange (page) {
      this.currentPageNumber = page
      const newArray = []
      for (let index = this.currentPageSize * (page - 1); index < this.currentPageSize * page; index++) {
        if (index < this.showArray.length) {
          newArray.push(this.showArray[index])
        } else {
          break
        }
      }

      this.contractData = newArray
    },
    loadContractDynamic () {
      let startDate = ParseDate(new Date())
      let endDate = ParseDate(new Date())

      if (this.chooseDateType === '2') {
        startDate = GetDateStr(startDate, 0)
        endDate = GetDateStr(endDate, 0)
      } else if (this.chooseDateType === '3') {
        startDate = GetDateStr(startDate, -5)
      }

      const queryModel = {
        endDate: endDate,
        publisherId: this.publisherId,
        startDate: startDate,
        companyId: this.companyId
      }

      listContracts(queryModel).then(res => {
        this.dynamicContractData = res
      })

      listOrders(queryModel).then(res => {
        this.dynamicOrderData = res
      })
    },
    rowClassName (row, index) {
      return row.bid === this.chooseRightTableId ? 'situation-table-choose-row' : 'situation-table-row'
    },
    handleClickRow (params, index) {
      this.chooseRightTableId = (this.chooseRightTableId === params.bid) ? null : params.bid
      this.$store.commit('set_situation_rightTableId', this.chooseRightTableId)
      this.$store.commit('set_situation_rightTableRowBean', params)
    },
    formatMoney (money) {
      return toMoney(money)
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    },
    orderType () {
      return this.$store.state.situationWeb.orderType
    },
    gbId () {
      return this.$store.state.situationWeb.gbId
    },
    gbType () {
      return this.$store.state.situationWeb.gbType
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadContractListData()
      }
    },
    chooseDateType () {
      this.loadContractDynamic()
    },
    companyId () {
      this.loadContractListData()
      this.loadContractDynamic()
    },
    orderType () {
      this.loadContractListData()
    },
    gbId () {
      this.loadContractListData()
    }
  }
}
</script>

<style>
.contractTable .ivu-table-cell{
  padding-left: 2px;
  padding-right: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  box-sizing: border-box;
}
</style>
